<template>
  <b-button variant="primary" @click="create">
    <b-spinner v-if="isLoading" small class="mr-50" />
    <feather-icon v-else icon="PlusIcon" class="mr-50" />
    <span class="align-middle">Add New</span>
  </b-button>
</template>

<script>
import service from "../../service";

export default {
  props: {
    display_order: { type: Number, required: true },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async create() {
      this.isLoading = true;
      let new_item = await service.create({
        display_order: this.display_order,
      });
      this.isLoading = false;
      if (new_item) {
        this.$router.push({
          name: "quest-eternal-detail",
          params: { eternal_id: new_item._id },
        });
      }
    },
  },
};
</script>
